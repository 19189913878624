import React from "react"
import styled from "styled-components"

const Container = styled.footer`
  min-height: 5em;
  background-color: #23272a;
  margin-top: 2em;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Copyright = styled.div`
  float: right;
`

const Socials = styled.div`
  float: left;
`

const Link = styled.a`
  margin: 0 0.5em;
  color: white;
  text-decoration: none;
  &:hover {
    color: rgba(256, 256, 256, 0.8);
  }
  &:active {
    color: white;
  }
  &:visited {
    color: white;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 2em;
  max-width: 1000px;
`

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <Socials>
          <Link href="https://discord.pings.gq">Discord</Link>|
          <Link href="https://twitter.pings.gq">Twitter</Link>
        </Socials>
        <Copyright>
          Copyright © {new Date().getFullYear()}{" "}
          <Link href="/" style={{ margin: 0 }}>
            pings.gq
          </Link>
        </Copyright>
      </Wrapper>
    </Container>
  )
}

export default Footer
