import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { Tooltip } from "react-tippy"
import { useToasts } from "react-toast-notifications"

import "react-tippy/dist/tippy.css"

const Container = styled.header`
  margin-bottom: 2em;
  padding: 1em 10%;
  background-color: #ffffff77;
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    padding-bottom: 1em;
  }
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.23);
`

const Heading = styled.h1`
  margin: 0 1.5em 0 0;
  font-weight: 600;
  @media (max-width: 800px) {
    margin-right: 0;
  }
  text-shadow: 2px 2px #555555;
  &:hover {
    text-shadow: -2px -2px #555555;
  }
  transition: text-shadow 0.1s ease;
  transform: translate(0px, -3px);
`

const Element = styled.div`
  font-size: 1.2em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -1px;
  margin-right: 1em;
  @media (max-width: 800px) {
    margin-right: 0;
  }
  &:hover {
    transform: translate(0px, -2px);
  }
  transition: transform 0.1s ease;
`

const Nav = styled.div`
  float: left;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const Online = styled.div`
  font-size: 1.2em;
  font-weight: 900;
  color: #555;
  text-transform: uppercase;
  letter-spacing: -1px;
  float: right;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    text-align: right;
  }
`

const Amount = styled.span`
  color: #ffaa00;
  margin-right: 0.25em;
`

const Header = ({ siteTitle, page }) => {
  const [status, setStatus] = useState()
  const [amount, setAmount] = useState()
  const { addToast } = useToasts()

  useEffect(() => {
    setStatus("Loading...")
    axios.get("https://api.minetools.eu/ping/pings.gq").then(res => {
      if (res.data.players) {
        setStatus(`Players Online`)
        setAmount(res.data.players.online)
      } else setStatus("Server Offline")
    })
  }, [])

  return (
    <Container>
      <Nav>
        <Heading>
          <Link
            to="/"
            style={{
              color: `#FFAA00`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </Heading>
        <Element
          style={page === "staff" ? { transform: "translate(0px, -2px)" } : {}}
        >
          <Link
            to="/staff"
            style={
              page === "staff"
                ? { color: `#FFAA00`, textDecoration: `none` }
                : {
                    color: `#555`,
                    textDecoration: `none`,
                  }
            }
            onMouseEnter={e =>
              page === "staff" ? "" : (e.target.style.color = "#000")
            }
            onMouseLeave={e =>
              page === "staff" ? "" : (e.target.style.color = "#555")
            }
          >
            Staff
          </Link>
        </Element>
        <Element
          style={
            page === "support" ? { transform: "translate(0px, -2px)" } : {}
          }
        >
          <Link
            to="/support"
            style={
              page === "support"
                ? { color: `#FFAA00`, textDecoration: `none` }
                : {
                    color: `#555`,
                    textDecoration: `none`,
                  }
            }
            onMouseEnter={e =>
              page === "support" ? "" : (e.target.style.color = "#000")
            }
            onMouseLeave={e =>
              page === "support" ? "" : (e.target.style.color = "#555")
            }
          >
            Support
          </Link>
        </Element>
        <Element>
          <a
            href="https://store.pings.gq/"
            style={{
              color: `#555`,
              textDecoration: `none`,
            }}
            onMouseEnter={e => (e.target.style.color = "#000")}
            onMouseLeave={e => (e.target.style.color = "#555")}
          >
            Store
          </a>
        </Element>
      </Nav>

      <Online>
        <Tooltip
          title="Click to copy IP"
          animation="shift"
          animateFill={false}
          arrow="true"
          onClick={() => {
            navigator.clipboard.writeText("pings.gq")
            addToast("Successfully copied the server IP!", {
              appearance: "success",
            })
          }}
          style={{ cursor: "pointer" }}
        >
          <Amount>{amount}</Amount>
          {status}
        </Tooltip>
      </Online>
    </Container>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
